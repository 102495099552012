



















import Vue from "vue";
import { Tabs, Tab, List, Empty, Cell, CellGroup } from "vant";
import Moment from "moment";
Moment.locale("zh-cn");

export default Vue.extend({
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Empty.name]: Empty,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      tabName: "ebike",
      tabTitle: "车辆",
      list: [],
      ebikeList: [],
      batteryList: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getDeviceChangeDesc(item: { oldValues: string[]; newValues: string[] }, index: number) {
      const oldValue = item.oldValues[index];
      const newValue = item.newValues[index];
      if (oldValue == "-1") {
        return "新增 " + newValue;
      } else if (newValue == "-1") {
        return "移除 " + oldValue;
      } else {
        return item.oldValues[index] + " => " + item.newValues[index];
      }
    },
    onTabChange(name: string, title: string) {
      this.tabTitle = title;
      if (name == "ebike") {
        this.list = this.ebikeList;
      } else if (name == "battery") {
        this.list = this.batteryList;
      }
    },
    formatTime(timestamp: number) {
      return Moment(timestamp).format("lll");
    },
    getData() {
      this.$axios.post("/api/manage/getBatteryPlanOrderDeviceChangeLog", { planOrderId: this.$route.params.planOrderId }).then(e => {
        const res = e.data;
        const data = res.data;
        this.ebikeList = data.ebikeList;
        this.batteryList = data.batteryList;
        this.onTabChange(this.tabName, this.tabTitle);
      });
    }
  }
});
